import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import styled from "@emotion/styled";
import {css} from "@emotion/core";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart } from '@fortawesome/free-solid-svg-icons'
import { OutboundLink } from "gatsby-plugin-google-analytics"
import Container from 'react-bootstrap/Container';

const IndexSection2Section1Background = styled.div`
  background-color: ${props => props.theme.colors.white};
  color: ${props => props.theme.colors.gray_dark};
  font: 20px/28px Roboto;
  position:relative;
  overflow: hidden;
`

const SectionItem = styled.div`
  display: inline-block;
  @media(max-width:767px){
    margin-top:0;
    margin-bottom:50px;
  }
`

const SecondSectionTitle = styled.div`
  color: ${props => props.theme.colors.gray_dark};
  font: 58px/62px Abril Fatface;
  margin-top:100px;
  @media(max-width:991px){
    margin-top:80px;
    margin-bottom:30px;
  }
  @media(max-width:767px){
    margin-top:20px;
    font: 2.5rem/3rem Abril Fatface;
  }
  @media(max-width:320px){
    font: 2rem/2.5rem Abril Fatface;
  }
`
const SecondSectionText = styled.div`
  color: ${props => props.theme.colors.gray_dark};
  font: 19px/28px Roboto;
  margin-top:35px;
  max-width:90%;
  @media(max-width:767px){
    margin-top:20px;
  }
  .row {
    margin-bottom:20px;
  }
  span {
    margin-top:20px;
    margin-left:7px;
  }
`
const FAHeart = styled(FontAwesomeIcon)`
  font-size:1rem;
  color: ${props => props.theme.colors.accent_yellow_hover};
`
const SecondSectionButton = styled.div`
  margin-bottom:100px;
  margin-top:5px;
  @media(max-width:768px) {
    margin-bottom:30px;
  }
`
const ButtonFill2 = styled(Button)`
  height: 60px;
  color: ${props => props.theme.buttons.primary_button_violet.normal.color} !important;
  background-color: ${props => props.theme.buttons.primary_button_violet.normal.background_color} !important;
  border-color: ${props => props.theme.buttons.primary_button_yellow_green.normal.border_color} !important;
  font: 22px/22px Roboto;
  transition: 0.25s ease-out;
  &:hover {
    color: ${props => props.theme.buttons.primary_button_violet.hovered.color} !important;
    background-color: ${props => props.theme.buttons.primary_button_violet.hovered.background_color} !important;
    border-color: ${props => props.theme.buttons.primary_button_violet.hovered.border_color} !important;
    box-shadow: 0px 3px 20px ${props => props.theme.buttons.primary_button_violet.hovered.shadow} !important;
    @media(min-width:1199px){
      margin-top:-3px;
    }
  }
  &:focus, &.focus, &:active, &.active {
    color: ${props => props.theme.buttons.primary_button_violet.pressed.color} !important;
    background-color: ${props => props.theme.buttons.primary_button_violet.pressed.background_color} !important;
    border-color: ${props => props.theme.buttons.primary_button_yellow_green.pressed.border_color} !important;
    box-shadow: none !important;
    @media(min-width:1199px){
      margin-top:-3px;
    }
  }
  @media(min-width:1200px){
    width:223px;
  }
  @media(max-width:1199px){
    width:100%;
  }
`
const ButtonOutline2 = styled(Button)`
  height: 60px;
  color: ${props => props.theme.buttons.secondary_big_button_violet.normal.color} !important;
  background-color: ${props => props.theme.colors.white} !important;
  border-color: ${props => props.theme.buttons.secondary_big_button_violet.normal.border_color} !important;
  font: 22px/22px Roboto;
  transition: 0.25s ease-out;
  &:hover {
    color: ${props => props.theme.buttons.secondary_big_button_violet.hovered.color} !important;
    background-color: ${props => props.theme.buttons.primary_button_violet.pressed.background_color} !important;
    border-color: ${props => props.theme.buttons.secondary_big_button_violet.hovered.border_color} !important;
    box-shadow: 0px 3px 20px ${props => props.theme.buttons.secondary_big_button_violet.normal.shadow} !important;
    @media(min-width:1199px){
      margin-top:-3px;
    }
    @media(max-width:767px){
      margin-top:17px;
    }
  }
  &:focus, &.focus, &:active, &.active {
    color: ${props => props.theme.buttons.secondary_big_button_violet.pressed.color} !important;
    background-color: ${props => props.theme.buttons.primary_button_violet.pressed.background_color} !important;
    border-color: ${props => props.theme.buttons.secondary_big_button_violet.pressed.border_color} !important;
    box-shadow: none !important;
    @media(min-width:1199px){
      margin-top:-3px;
    }
  }
  @media(min-width:1200px){
    margin-left:25px;
  }
  @media(max-width:1199px){
    width:100%;
  }
  @media(max-width:767px){
    margin-bottom:30px;
  }
`

export default function IndexSection2Section1(props) {
  return (
  <IndexSection2Section1Background backgroundColor={props.Background} color={props.TextColor}>
      <img alt="WomenWorks - He For She" src="/page-he-for-she-section1-img1.jpg" 
        css={css`
          @media(max-width:991px) {
            display:none;
          }
          @media(min-width:992px) {
            position: absolute;
            right: 0;
            top:100px;
            max-width: 45vw;
            object-fit: cover;
          }
          @media(min-width:1600px) {
            display:none;
          }
      `}></img>
      <Container>
          <SectionItem>
            <Row>
              <Col xs="12" lg="7">
                <SecondSectionTitle>
                  Join as a mentor and expand your impact
                </SecondSectionTitle>
                </Col>
                <Col xs="12" lg="5">
                <img alt="WomenWorks - He For She" src="/page-he-for-she-section1-img1.jpg" 
                  css={css`
                    @media(max-width:767px) {
                      width:100%;
                      margin-bottom:15px;
                    }
                    @media(min-width:768px) {
                      object-fit: cover;
                      height: 250px;
                      width:100%;
                      margin-bottom:30px;
                      object-position: 15% 15%;
                    }
                    @media(min-width:992px) {
                      display:none;
                    }
                    @media(min-width:1600px) {
                      display:block;
                      position:absolute;
                      object-fit:scale-down;
                      width: 832px;
                      height: 800px;
                      margin-left: 15%;
                    }
                `}></img>
              </Col>
              <Col xs="12" lg="7">
          <SecondSectionText>
            <Row>
              <Col xs="auto" css={css`padding-right:0;`}>
                <FAHeart icon={faHeart} />
              </Col>
              <Col css={css`padding-left:7px;`}>Connect with Indonesian women from varying generations and stages in their career lives who needs your help</Col>
            </Row>
            <Row >
              <Col xs="auto" css={css`padding-right:0;`}>
                <FAHeart icon={faHeart} />
              </Col>
              <Col css={css`padding-left:7px;`}>Share your experience and expertise to inspire others and capitalize your knowledge</Col>
            </Row>
            <Row >
              <Col xs="auto" css={css`padding-right:0;`}>
                <FAHeart icon={faHeart} />
              </Col>
              <Col css={css`padding-left:7px;`}>Network with other mentors and top-notch women leaders in your impact journey</Col>
            </Row>
          </SecondSectionText>
          <SecondSectionButton>
            <Row>
              <Col sm lg="5" xl="4" css={css`@media(max-width:768px){margin-bottom:20px;}`}>
                <OutboundLink 
                    id="Homepage5-BecomeMentor-Button"
                    target="_blank"
                    href={`https://bit.ly/womenworksmentorsignupnew`}>
                  <ButtonFill2>Become a mentor </ButtonFill2>
                </OutboundLink>
              </Col>
              <Col sm lg="7">
                <OutboundLink 
                    id="Homepage5-ApplyMaleMentor-Button"
                    target="_blank"
                    href={`https://bit.ly/womenworksmentorsignupnew`}>
                  <ButtonOutline2>Apply as a Male Mentor</ButtonOutline2>
                </OutboundLink>
              </Col>
            </Row>
          </SecondSectionButton>
        </Col>
            </Row>
          </SectionItem>
      </Container>
  </IndexSection2Section1Background>
  )
}

